<template>
  <div>
    <b-row class="mt-4" no-gutters>
      <b-col cols="12" md="4" v-for="(t, index) in localTasksList" :key="t.id" class="p-3">
        <b-card no-body class="overflow-hidden bg-dark-jungle rounded-lg" text-variant="white">
          <b-card-header href="#" class="d-flex justify-content-between align-items-center" @click.prevent="goToTask(t.id)">
            <h5 class="mb-0 text-white" style="cursor: pointer;">{{ t.name }}</h5>
            <b-button @click.stop="triggerUncheckTask(t.id)" :variant="t.hovered ? 'outline-danger' : 'outline-success'" @mouseover="hover(index, true)" @mouseleave="hover(index, false)" :disabled="isSending" pill>
              <img :src="require(`@/assets/icons/${t.hovered ? 'uncheck' : 'check'}.svg`)" width="20" height="20"  />
            </b-button>
          </b-card-header>
          <b-card-body>
            <p>Completed: {{ formatDate(t.doneAt) }}</p>
            <small class="text-muted">Created on: {{ formatDate(t.createdAt) }}</small>
            <br>
            <small class="text-muted">Duration: {{ calculateDuration(t.createdAt, t.doneAt) }}</small>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { UNCHECK } from "@/store/actions";
import { BRow, BCol, BCard, BCardHeader, BCardBody, BButton } from "bootstrap-vue";

export default {
  name: "Archive",
  components: { BRow, BCol, BCard, BCardHeader, BCardBody, BButton },
  computed: {
    ...mapGetters("projectsTasks", ["completedTasks"]),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  data: () => ({
    localTasksList: [],
    isSending: false,
  }),
  mounted() {
    this.initializeLocalTasksList();
  },

  watch: {
    completedTasks() {
      this.initializeLocalTasksList();
    },
  },
  methods: {
    ...mapActions("projectsTasks", { uncheckTask: UNCHECK }),
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-US", {
        year: 'numeric', month: 'long', day: 'numeric',
      });
    },
    calculateDuration(createdAt, doneAt) {
      const duration = doneAt - createdAt;
      const durationInDays = duration / (1000 * 60 * 60 * 24);
      if (durationInDays < 1) {
        return "less than a day";
      } else {
        return `${Math.round(durationInDays)} day(s)`;
      }
    },
    async triggerUncheckTask(taskId) {
      this.isSending = true;
      const { projectId } = this

      await this.uncheckTask({ projectId, taskId })
      this.initializeLocalTasksList();
      this.isSending = false
    },
    goToTask(taskId) {
      this.$router.push({ name: "ProjectTask", params: { taskId } });
    },
    initializeLocalTasksList() {
      this.localTasksList = this.completedTasks().map(task => ({ ...task, hovered: false }));
    },
    hover(index, state) {
      this.$set(this.localTasksList, index, { ...this.localTasksList[index], hovered: state });
    },
    
  }
};
</script>
